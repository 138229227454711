import { React, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function Login() {
  const history = useHistory();
  const [username, setUsername] = useState(0);
  const [password, setPassword] = useState(0);

  const login = (event) => {
    event.preventDefault();
    let data = {
      username: username,
      password: password,
    };

    if (!data) {
      toast.error("Forbiden, No credentials");
    }
    axios
      .get("https://library.kisasisecondaryschool.co.ke/sanctum/csrf-cookie")
      //.get("https://localhost:8000/sanctum/csrf-cookie")
      .then(
        axios.post("/login", data).then((response) => {
          console.log(response.data.isAdmin);
          if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("auth", true);
            localStorage.setItem("name", response.data.name);
            if (response.data.isAdmin === 1) {
              localStorage.setItem("isAdmin", true);
              localStorage.setItem("isLib", true);
              console.log("is admin");
            }
            if (response.data.isLib === 1) {
              localStorage.setItem("isAdmin", "");
              localStorage.setItem("isLib", true);
              console.log("is lib");
            }
            if (response.data.isAdmin === 0) {
              localStorage.setItem("isAdmin", "");
            }
            localStorage.getItem("auth")
              ? history.push("/admin/dashboard")
              : toast.error("User name or password is incorect");
          } else {
            toast.error(response.data);
          }
        })
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error("User name or password is incorect");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4 mt-24">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200  border-0">
              <div className="rounded-t mb-0 px-6 py-3">
                <p className="text-semibold w-full text-center p-2 text-3xl font-bold mb-6">
                  Login
                </p>
                <div className="flex justify-center items-center">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-12 h-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg> */}
                </div>
              </div>
              <div className="flex-auto px-2 lg:px-10 py-5 pt-0">
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      User name
                    </label>
                    <input
                      onChange={(event) => setUsername(event.target.value)}
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="user name"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      onChange={(event) => setPassword(event.target.value)}
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded-full text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div>
                  <div className="text-center mt-6">
                    <button
                      className="bg-lightBlue-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={login}
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200 underlo"
                >
                  <small></small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link
                  to="/auth/egister"
                  className="text-blueGray-200 underline"
                >
                  <small>Forgot password?</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
