
import axios from 'axios';
axios.defaults.headers.common['withCredentials'] = true;
axios.defaults.headers.common['Authorization'] = ' Bearer '+localStorage.getItem('token');
axios.defaults.headers.common['Auth-Token'] = localStorage.getItem('token');
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
//axios.defaults.baseURL='http://127.0.0.1:8000/api';
axios.defaults.baseURL='https://library.kisasisecondaryschool.co.ke/api';

 axios.interceptors.request.use(function (config) {
    document.body.classList.add('loader');
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    document.body.classList.remove('loader');
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });





