import React from "react";

export default function FooterAdmin() {
  return (
    <>
      <footer className="block py-4">
        <div className="container mx-auto px-4">
          <hr className="mb-4 border-b-1 border-blueGray-200" />
          <div className="flex font-light flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4">
              <div className="text-sm text-blueGray-500 font-light py-1 text-center md:text-left">
                Copyright © {new Date().getFullYear()}{"  "}
                <a
                  href="https://www.Gozerolabs.com?ref=nr-footer-admin"
                  className="text-blueGray-500 hover:text-blueGray-700 text-sm font-light py-1"
                >
                  Gozerolabs
                </a>
              </div>
            </div>
            <div className="w-full md:w-8/12 px-4">
              <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                <li>
                  <a
                    href="https://www.Gozerolabs.co.ke?ref=nr-footer-admin"
                    className="text-blueGray-600 hover:text-blueGray-800 text-sm font-light block py-1 px-3"
                  >
                    Gozerolabs
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.Gozerolabs.co.ke/presentation?ref=nr-footer-admin"
                    className="text-blueGray-600 hover:text-blueGray-800 text-sm font-light block py-1 px-3"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="api.whatsapp.com/send?phone=254703440095"
                    className="text-blueGray-600 hover:text-blueGray-800 text-sm font-light block py-1 px-3"
                  >
                   <i className="fas fa-whatsapp text-emerald-500"></i>WhatsApp
                  </a>
                </li>
                <li>
                  <a
                    href="api.whatsapp.com/send?phone=254703440095"
                    className="text-blueGray-600 hover:text-blueGray-800 text-sm font-light block py-1 px-3"
                  >
                   <i className="fa fa-phone text-emerald-500"></i > 0703440095
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
