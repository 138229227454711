import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import CardTable from "components/Cards/CardTable.js";
import Search from "components/Search/Search.jsx";
import InputModal from "components/Modals/InputModal.jsx";

export default function Books() {
  //

  const [reload, setReload] = useState(false);
  const [query, setQuery] = useState([]);
  const [books, setBooks] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [links, setLinks] = useState([]);
  const [createInputs, setCreateInputs] = useState([]);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal4, setShowModal4] = useState(false); //staff issue
  const [showModal3, setShowModal3] = useState(false); //student issue
  const [showModal2, setShowModal2] = useState(false);
  const [showModal, setShowModal] = useState(false); // add book
  //book isssue
  const [book_issue_id, setBook_issue_id] = useState(false);
  const [book_edit_id, setBook_edit_id] = useState();
  const [book_edit_name, setBook_edit_name] = useState();
  const [book_issue_name, setBook_issue_name] = useState(false);
  const [book_issue_identity, setBook_issue_identity] = useState(false);
  //set  options
  const [category_data, setCategoryData] = useState([]);
  const [publisher_data, setPublisherData] = useState([]);
  const [author_data, setAuthorData] = useState([]);
  const isLib = localStorage.getItem("isLib");

  //load category options
  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete item",
      message: "Action is irreversible",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios
              .post("/book/delete/" + id)
              .then((response) => {
                console.log(response);
                toast.success("Done");
              })
              .catch((err) => {
                console.log(err);
                toast.error("seems this data is in use");
              });
            reload ? setReload(false) : setReload(true);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  
  const handleLoadOptions = () => {
    axios.get("categories").then((response) => {
      setCategoryData(response.data);
    });
    axios.get("authors").then((response) => {
      setAuthorData(response.data);
    });

    axios.get("publishers").then((response) => {
      setPublisherData(response.data);
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCreateInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = () => {
    if (
      !createInputs.category_id ||
      !createInputs.name ||
      !createInputs.cost ||
      !createInputs.isbn ||
      !createInputs.number ||
      !createInputs.auther_id ||
      !createInputs.publisher_id
    ) {
      console.log(createInputs);
      toast.error("Please fill all the fields");
      return 0;
    }

    console.log(createInputs);
    axios
      .post("book/create", createInputs)
      .then((response) => {
        console.log(response);
        setShowModal(false);
        toast.success("success");
        reload ? setReload(false) : setReload(true);
      })
      .catch((error) => {
        toast.error(error.message);
      });
    setCreateInputs([]);
  };

  const handleSubmitEdit = () => {
    console.log(createInputs);

    axios
      .post("book/update/" + book_edit_id, createInputs)
      .then((response) => {
        console.log(response);
        setShowModal(false);
        toast.success("success");
        reload ? setReload(false) : setReload(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };
  const handleIssue = (bookname, book_id) => {
    setShowModal3(true);
    setBook_issue_id(book_id);
    setBook_issue_name(bookname);
    setShowModal3(true);
    axios.get("book_issue/view/").then((response) => {});
  };

  const handleIssueStaff = (bookname, book_id) => {
    setShowModal4(true);
    setBook_edit_id(book_id);
  };

  const handleBookEdit = (bookname, book_id) => {
    setShowModal5(true);
    setBook_edit_id(book_id);
    setBook_edit_name(bookname);
    axios.get("book_issue_staff/view/").then((response) => {});
  };

  const issueBook = (book_id) => {
    axios
      .post("/book-issue/create", {
        adm: book_issue_identity,
        book_id: book_id,
      })
      .then((response) => {
        console.log(response);
        toast.info(response.data);
        reload ? setReload(false) : setReload(true);
      });
    setShowModal3(false);
  };

  const issueBookStaff = (book_id) => {
    axios
      .post("/book-issue-staff/create", {
        id_number: book_issue_identity,
        book_id: book_id,
      })
      .then((response) => {
        console.log(response);
        toast.info(response.data);
        reload ? setReload(false) : setReload(true);
      });
    setShowModal4(false);
  };
useEffect(() => {
  const page = pagination?.current_page || 1;
  const searchQuery = query ? `query=${encodeURIComponent(query)}` : ""; // Properly encode query
  const pageQuery = `page=${page}`;

  // Ensure we don't pass empty query parameters
  const fullQuery = [searchQuery, pageQuery].filter(Boolean).join("&");
  console.log(`/books?${fullQuery}`);
  axios
    .get(`/books?${fullQuery}`)
    .then(({ data }) => {
      setPagination(data);
      setLinks(data.links);
      setBooks(data.data);
    })
    .catch(console.log);
}, [query, reload, pagination?.current_page]);



  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
            tableTitle="All books"
            action={
              <>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  Add Book
                </button>
              </>
            }
            search={
              <Search
                input={
                  <input
                    class="h-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-60  pl-10   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search Books"
                  />
                }
              />
            }
            col_label1="BOOK NAME"
            col_label2="BOOK NUMBER"
            col_label3="CATEGORY"
            col_label4="AUTHOR"
            col_label5="ISBN"
            col_label6="STATUS"
            col_label7="ISSUE"
            tableRows={books.map((book, index) => (
              <tr key={index}>
                <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left ">
                  {book.name}
                </th>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.number}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.category.name}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.auther ? book.auther.name : null}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.isbn}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.status === "Y" ? (
                    <p className="text-center">
                      {" "}
                      <i className="fas fa-circle text-emerald-500 mr-2"></i>{" "}
                    </p>
                  ) : (
                    <p className="text-center">
                      {" "}
                      <i className="fas fa-circle text-red-500 mr-2"></i>
                    </p>
                  )}
                </td>
                <td>
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    {isLib ? (
                      <TableDropdown
                        action1={
                          book.status === "Y" ? (
                            <>
                              <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => handleIssue(book.name, book.id)}
                              >
                                Student
                              </button>{" "}
                              <br />{" "}
                              <button
                                className="bg-emerald-500 text-white px-5 active:bg-lightBlue-600 font-bold uppercase text-xs  py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() =>
                                  handleIssueStaff(book.name, book.id)
                                }
                              >
                                Staff
                              </button>
                              <br />
                              <button
                                className="bg-emerald-500 text-white px-5 active:bg-lightBlue-600 font-bold uppercase text-xs  py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() =>
                                  handleBookEdit(book.name, book.id)
                                }
                              >
                                Edit
                              </button>
                              <br />
                              <button
                                className="bg-red-500 text-white px-4 active:bg-lightBlue-600 font-bold uppercase text-xs  py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => handleDelete(book.id)}
                              >
                                Delete
                              </button>
                            </>
                          ) : (
                            <button
                              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => toast.info("Book Not Available")}
                            >
                              Not Available
                            </button>
                          )
                        }
                      />
                    ) : (
                      <p className="text-base">Disabled</p>
                    )}
                  </td>
                </td>
              </tr>
            ))}
          />
        </div>
        <div className="w-full p-4 flex items-center justify-center text-center">
          <div className="bg-lightBlue-900 p-2 font-light text-white w-full mb-12 px-4 rounded-lg flex gap-2 justify-center">
            <span className="px-4">
              Showing page {pagination.current_page} of {pagination.last_page}
            </span>
              {links.map((link, index) => {
                let label = link.label.replace(/&laquo;|&raquo;/g, "").trim();
                
                return (
                  <button
                    key={index}
                    className={`px-3 py-1 rounded-full ${
                      link.active ? "bg-orange-500 text-white" : "bg-gray-300 text-black"
                    } ${!link.url ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={() => {
                      if (link.url) {
                        const urlParams = new URLSearchParams(link.url.split("?")[1]); // Extract query params only
                        setQuery(urlParams.get("query") || ""); // Ensure only query value is passed
                        setPagination((prev) => ({ ...prev, current_page: urlParams.get("page") || 1 }));
                      }
                    }}
                    disabled={!link.url}
                  >
                    {label}
                  </button>
                );
              })}

          </div>
        </div>


      </div>
      {/*modals*/}

      <InputModal
        modalStateProp={showModal}
        modalTitle="Add Book"
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="Category_id"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Subject/Category
            </label>
            <select
              name="category_id"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.category_id || ""}
              onChange={handleChange}
            >
              <option>Select...</option>
              {category_data.map((category, index) => (
                <option value={category.id}>{category.name}</option>
              ))}
            </select>
            <label
              for="name"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Book Title
            </label>
            <input
              id="name"
              name="name"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.name || ""}
              onChange={handleChange}
            />
            <label
              for="cost"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Unit cost (ksh)
            </label>
            <input
              id="cost"
              name="cost"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.cost || ""}
              onChange={handleChange}
            />
            <label
              for="number"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Book Number
            </label>
            <input
              id="number"
              name="number"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.number || ""}
              onChange={handleChange}
            />
            <label
              for="isbn"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              ISBN
            </label>
            <input
              id="isbn"
              name="isbn"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.isbn || ""}
              onChange={handleChange}
            />
            <label
              for="author_id"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Author
            </label>
            <select
              id="auther_id"
              name="auther_id"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.auther_id || ""}
              onChange={handleChange}
            >
              <option>Select...</option>
              {author_data.map((aut, index) => (
                <option value={aut.id}>{aut.name}</option>
              ))}
            </select>
            <label
              for="publisher_id"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Publisher
            </label>
            <select
              id=""
              name="publisher_id"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.publisher_id || ""}
              onChange={handleChange}
            >
              <option>Select...</option>
              {publisher_data.map((pub, index) => (
                <option value={pub.id}>{pub.name}</option>
              ))}
            </select>
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </>
        }
      />

      <InputModal
        modalStateProp={showModal2}
        modalTitle="Issue Book"
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal2(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="Category"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Student Admission Number
            </label>
            <input
              id="bookname"
              type="bookname"
              name="bookname"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.adm || ""}
              onChange={handleChange}
            />
            <label
              for="isbn"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Book Title
            </label>
            <input
              id="publisher"
              name="publisher"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.username || ""}
              onChange={handleChange}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal2(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal2(false)}
            >
              Save
            </button>
          </>
        }
      />

      <InputModal
        modalStateProp={showModal3}
        modalTitle={<p className="font-semibold">Book:{book_issue_name}</p>}
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal3(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="Category"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Student Admission Number
            </label>
            <input
              id="bookname"
              type="bookname"
              name="bookname"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              onChange={(e) => setBook_issue_identity(e.target.value)}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal3(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => issueBook(book_issue_id)}
            >
              Issue
            </button>
          </>
        }
      />

      <InputModal
        modalStateProp={showModal4}
        modalTitle={<p className="font-semibold">Book:{book_issue_name}</p>}
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal4(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="Category"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Staff employee/id Number
            </label>
            <input
              id="bookname"
              type="bookname"
              name="bookname"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              onChange={(e) => setBook_issue_identity(e.target.value)}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal4(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => issueBookStaff(book_issue_id)}
            >
              Issue
            </button>
          </>
        }
      />
      <InputModal
        modalStateProp={showModal5}
        modalTitle={<p>Adit Book :{book_edit_name}</p>}
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal5(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="Category_id"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Subject/Category
            </label>
            <select
              name="category_id"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.category_id || ""}
              onChange={handleChange}
            >
              <option>Select...</option>
              {category_data.map((category, index) => (
                <option value={category.id}>{category.name}</option>
              ))}
            </select>
            <label
              for="name"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Book Title
            </label>
            <input
              id="name"
              name="name"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.name || ""}
              onChange={handleChange}
            />
            <label
              for="cost"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Unit cost (ksh)
            </label>
            <input
              id="cost"
              name="cost"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.cost || ""}
              onChange={handleChange}
            />
            <label
              for="number"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Book Number
            </label>
            <input
              id="number"
              name="number"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.number || ""}
              onChange={handleChange}
            />
            <label
              for="isbn"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              ISBN
            </label>
            <input
              id="isbn"
              name="isbn"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.isbn || ""}
              onChange={handleChange}
            />
            <label
              for="author_id"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Author
            </label>
            <select
              id="auther_id"
              name="auther_id"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.auther_id || ""}
              onChange={handleChange}
            >
              <option>Select...</option>
              {author_data.map((aut, index) => (
                <option value={aut.id}>{aut.name}</option>
              ))}
            </select>
            <label
              for="publisher_id"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Publisher
            </label>
            <select
              id=""
              name="publisher_id"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.publisher_id || ""}
              onChange={handleChange}
            >
              <option>Select...</option>
              {publisher_data.map((pub, index) => (
                <option value={pub.id}>{pub.name}</option>
              ))}
            </select>
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={() => setShowModal5(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={() => handleSubmitEdit()}
            >
              Save
            </button>
          </>
        }
      />
      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
